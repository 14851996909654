<template>
  <Menu />
  <Popup />
  <router-view></router-view>
</template>

<script>
import Menu from './components/common/Menu'
import Popup from './components/common/Popup'

export default {
  name: 'App',
  components: {
    Menu,
    Popup,
  }
}
</script>

<style>
#app {
  overflow-x: hidden;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: whitesmoke;
}
</style>
