<template>
  <div class="lg:mt-28"></div>
  <SectionTitle id="videos" name="VIDÉOS"  />
  <Loader :loaded="videos.length > 0" />
  <div v-if="videos.length > 0" class="hidden lg:flex lg:mx-8 my-1">
    <div class="mx-2 pt-1 font-bold text-red-800">
      Filtrer : 
    </div>
    <div v-bind:class="{ 'bg-bootscutblue': !filtered}" 
      class="rounded-full bg-gray text-white px-4 py-1 cursor-pointer font-medium" v-on:click="filterBy('ALL')">
      <span v-if="!filtered">
        <i class="fas fa-check"></i>
      </span>
      Tout
    </div>
    <div class="rounded-full ml-1 bg-red text-white px-4 py-1 cursor-pointer font-medium" v-on:click="filterBy('clip')">
      <span v-if="filter == 'clip'">
        <i class="fas fa-check"></i>
      </span>
      clip
    </div>
    <div class="rounded-full ml-1 bg-orange text-white px-4 py-1 cursor-pointer font-medium" v-on:click="filterBy('live')">
      <span v-if="filter == 'live'">
        <i class="fas fa-check"></i>
      </span>
      live
    </div>
    <div class="rounded-full ml-1 bg-green text-white px-4 py-1 cursor-pointer font-medium" v-on:click="filterBy('lyrics')">
      <span v-if="filter == 'lyrics'">
        <i class="fas fa-check"></i>
      </span>
      lyrics
    </div>
  </div>
  <div class="grid grid-cols-1 lg:grid-cols-3 lg:mx-8 my-1">
    <Video data-aos="flip-left" :data-aos-delay="getDelay(video)" v-for="video in filteredVideos" :key="video.name" :video="video" />
  </div>
</template>
<script>
import SectionTitle from '../common/SectionTitle';
import Video from './Video';
import axios from 'axios';

export default {
  name: 'Videos',
  data: function() {
    return { videos: [], filteredVideos: [], filtered: false, filter: ''}
  },
  methods: {
    filterBy: function(type) {
      if(type == 'ALL') {
        this.filtered = false;
        this.filter = '';
        this.filteredVideos = this.videos;
        return;
      }
      if (type == 'clip') {
        this.filteredVideos = this.videos.filter(s => s.type == 'clip')
        this.filter = 'clip';
      }
      if (type == 'live') {
        this.filteredVideos = this.videos.filter(s => s.type == 'live')
        this.filter = 'live';
      }
      if (type == 'lyrics') {
        this.filteredVideos = this.videos.filter(s => s.type == 'lyrics')
        this.filter = 'lyrics';
      }
      this.filtered = true;
    },
    getDelay: function(video) {
      if (this.filtered)
        return 0;
      return video.id * 100;
    }
  },
  components: {SectionTitle, Video},
  mounted: async function () {
    const response = await axios.get('/.netlify/functions/videos');
    this.videos = response.data;
    for(var i = 0; i < this.videos.length; i++) {
      this.videos[i].id = i;
    }
    this.filteredVideos = this.videos;
  }
}
</script>
