<template>
<div id="hello" class="bg h-screen py-16 lg:py-32 bg-bootscutblue2 absolute w-full">
  <img src="../../assets/note2.png" class="note absolute hidden bottom-16 left-20 floatting-reverse z-10 opacity-90 " />
  <img src="../../assets/note2.png" class="note absolute hidden top-1/2 left-5 floatting z-10 opacity-90" />
  <img src="../../assets/note1.png" class="note absolute hidden lg:block  absolute top-1/4 left-1/4 floatting-reverse z-10 opacity-90" />
  <img src="../../assets/note2.png" class="note absolute hidden lg:block absolute top-36 left-48 floatting z-10 opacity-90" />
  <img src="../../assets/note1.png" class="note absolute hidden lg:block absolute top-3/4 left-1/4 floatting-reverse z-10 opacity-90" />
  <img src="../../assets/note1.png" class="note absolute hidden lg:block top-1/3 left-24 floatting z-10 opacity-90" />
  <img src="../../assets/note1.png" class="note absolute hidden lg:blockbottom-1/4 right-1/4 floatting-reverse z-10 opacity-90" />
  <img src="../../assets/note2.png" class="note hidden lg:block absolute bottom-2/4 right-48 floatting z-10 opacity-90" />
  <img src="../../assets/note1.png" class="note hidden absolute lg:block top-24 right-24 floatting-reverse z-10 opacity-90" />
  <img src="../../assets/note2.png" class="note hidden absolute lg:block bottom-36 right-16 floatting z-10 opacity-90" />
  <img src="../../assets/note1.png" class="note hidden lg:block  absolute top-24 right-1/4 floatting-reverse z-10 opacity-90" />
  <img src="../../assets/note2.png" class="note hidden absolute lg:block top-16 left-1/4 floatting z-10 opacity-90" />
  
  <img src="../../assets/note1.png" class="note lg:hidden absolute bottom-4 right-8 floatting z-10 opacity-90" />
  <img src="../../assets/note2.png" class="note lg:hidden absolute bottom-12 left-12 floatting z-10 opacity-90" />
  <img src="../../assets/note2.png" class="note lg:hidden absolute bottom-16 right-2/3 floatting z-10 opacity-90" />
  <img src="../../assets/note1.png" class="note lg:hidden absolute top-8 left-8 floatting-reverse z-10 opacity-90" />
  <img src="../../assets/note2.png" class="note lg:hidden absolute top-4 right-8 floatting z-10 opacity-90" />
  <div class="absolute z-20 mx-auto">
    <img src="../../assets/logo.png" data-aos="zoom-in-up" class="w-11/12 mx-auto lg:w-1/4" />
    <p class="text-black lg:text-xl text-md lg:mt-4 mt-1 lg:tracking-wider">Bienvenue dans le monde de Bootscut !</p>
    <div class="flex justify-center mt-4 text-white">
      <router-link to="/music">
        <HelloButton title="Bootscut" icon="fa-solid fa-ghost" infos="Mon projet solo de punk/rock en français." />
      </router-link>
      <router-link to="/projets">
        <HelloButton title="Mes projets" icon="fa-solid fa-compact-disc" infos="Tous mes projets musicaux !" />
      </router-link>
    </div>
  </div>
</div>
</template>
<script>
import HelloButton from './HelloButton'

export default {
  name: 'Hello',
  components: {HelloButton},
  data: function() {
    return { showreel: false }
  }
}
</script>
<style scoped>
  .bg .note {
    width: 72px;
  }

	.floatting {
    animation: floatting 3s infinite;
  }

  .floatting-reverse {
    animation: floatting 3s infinite;
    animation-direction: reverse;
  }

  @keyframes floatting {
    0%, 100% {
      transform: translateY(-12%);
      animationTimingFunction: cubic-bezier(0, .7, .5, 1);
    }
    50% {
      transform: translateY(0);
      animationTimingFunction: cubic-bezier(0, .7, .5, 1);
    }
  }
</style>