<template>
    <AdminMenu />
    <EditShowPopUp :shown="shown" :close="close" :show="selectedShow" :editMode="editMode" />
    <div class="pt-24">
      <SectionTitle name="EDIT SHOWS" />
      <div class="w-48 bg-bootscutblue text-white rounded-lg font-bold p-2 mx-2 mt-2 mb-8 cursor-pointer" v-on:click="showNewShow()">
        <i class="fa-solid fa-plus pr-2"></i>Nouveau show
      </div>
      <div v-for="show in shows" :key="show.date" class="text-green-400">
        <div class="flex">
          <div class="w-48">{{ show.date }}</div>
          <div class="w-24">{{ show.artist}} </div>
          <div class="w-96">{{ show.place }}, {{show.city}} </div>
          <div class="ml-4 cursor-pointer text-bootscutblue" v-on:click="showEditShow(show)"><i class="fa-solid fa-pencil"></i></div>
        </div>
        <div class="my-1 mx-8">
          <div class="w-full h-0.5 bg-green-400 border-bootscutblue" />
        </div>
      </div>
    </div>
</template>
<script>
import SectionTitle from '../../common/SectionTitle'
import AdminMenu from '../AdminMenu'
import EditShowPopUp from './EditShowPopUp'
import axios from 'axios'

export default {
  data: function () {
    return { password: "", shown: false, shows: [], selectedShow: {}, editMode: false}
  },
  components: { SectionTitle, AdminMenu, EditShowPopUp },
  mounted: async function () {
    this.$store.commit('hideMenu');
    this.password = window.localStorage.getItem('pwd');
    const response = await axios.post("/.netlify/functions/auth", { password: this.password});
    if (response.status != 200)
      this.$router.push('/')
    const showsRes = await axios.get("/.netlify/functions/shows");
    this.shows = showsRes.data;
  },
  methods: {
    showNewShow: function() {
      this.selectedShow = null;
      this.editMode = false;
      this.shown = true;
    },
    showEditShow: function(show) {
      this.selectedShow = show;
      this.editMode = true;
      this.shown = true;
    },
    close: async function() {
      this.shown = false;
      const showsRes = await axios.get("/.netlify/functions/shows");
      this.shows = showsRes.data;
    }
  }
}
</script>