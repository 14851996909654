<template>
  <div class="lg:mt-28"></div>
  <SectionTitle name="TOUS MES PROJETS" />
  <ProjectsList />
</template>
<script>
import SectionTitle from '../common/SectionTitle'
import ProjectsList from './ProjectsList'

export default {
  components: { ProjectsList, SectionTitle },
  name: 'Music',
  data: function () {
    return { highlight: {}, albums: [] }
  },
  mounted: function () {
    this.$gtag.event('projects', { method: 'Google' })
  }
}
</script>