<template>
  <div class="m-2 relative shadow-xl rounded cursor-pointer">
    <img class="rounded hidden lg:block" :src="'img/' + video.cover" v-on:click="open()"/>
    <a :href="video.link"><img class="rounded lg:hidden" :src="'img/' + video.cover" /></a>
    <div class="absolute flex bottom-0 right-0 m-2 text-xs xl:text-sm">
      <div class="hidden xl:block pr-2 pl-2 rounded-full bg-red text-white bg-opacity-90" v-if="video.type == 'clip'">
        clip
      </div>
      <div class="hidden xl:block pr-2 pl-2 rounded-full bg-orange text-white bg-opacity-90" v-if="video.type == 'live'">
        live
      </div>
      <div class="hidden xl:block pr-2 pl-2 rounded-full bg-green text-white bg-opacity-90" v-if="video.type == 'lyrics'">
        lyrics
      </div>
      <div class="ml-1 pr-2 pl-2 rounded-full bg-bootscutblue text-white bg-opacity-90">
        {{ video.year }}
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "Video",
    props: ['video'],
    methods: {
      open: function () {
        var payload = {
          item: this.video,
          type: 'VIDEO'
        };
        this.$store.commit('open', payload);
      }
    }
  }
</script>
