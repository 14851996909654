<template>
  <div class="fixed bg-black bg-opacity-80 z-30 w-full h-full" v-show="opened" v-on:click="close()">
  </div>
  <div class="popup fixed z-40 bg-bootscutblue2 top-48 p-2 rounded"  v-show="opened" v-if="popupdata">
    <div class="absolute z-50 text-white top-0 right-0 my-2 mx-4">
      <i v-on:click="close()" class="fas fa-times text-white text-xl cursor-pointer"></i>
    </div>  

    <!-- TITLE -->
    <div class="text-white mb-2">
      <span class="text-xl">{{ popupdata.name.toUpperCase() }} ({{ popupdata.year }})</span><br />
    </div>

    <div style="text-align: center;" v-html="popupdata.youtube" class="w-full"></div>

  </div>
</template>
<script>
export default {
  name: 'Popup',
  data: function() {
    return { opened: false, popupdata: null, type: '', isRRJP: false }
  },
  methods: {
    open: function() {
      this.opened = true;
    },
    close: function() {
      this.opened = false;
    },
    getDescription: function() {
      if (this.popupdata.infos)
        return this.popupdata.infos;
      return '';
    },
    getCover: function() {
      if (this.type == 'GAME')
        return this.popupdata.galleryDir + '/' + this.popupdata.galleryImages[0];
      return this.popupdata.artcover;
    },
    getInfoUrl: function () {
      if (this.type == 'GAME')
        return '/' + this.type.toLowerCase() + '/' + this.popupdata.name;
      else if (this.type == 'ALBUM' && !this.isRRJP)
        return '/release/album/' + this.popupdata.name;
      else if (this.type == 'ALBUM' && this.isRRJP)
        return '/release/rrjp/' + this.popupdata.name;
      else 
        return '';
    },
    getPlayUrl: function () {
      return this.popupdata.itchioLink ? this.popupdata.itchioLink : this.popupdata.androidLink;
    }
  },
  mounted: function () {
    this.$store.subscribe((mutation) => {
      //OPEN
      if (mutation.type == 'open') {
        this.open();
        this.popupdata = this.$store.state.popupdata;
        this.type = this.$store.state.type;
        this.isRRJP = this.$store.state.isRRJP;
      }
    });
  }
}
</script>
<style scoped>
  .popup {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .m-whalf {
    max-width: 50%;
  }
</style>