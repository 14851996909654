<template>
  <div class="relative bg-bootscutblue w-32 lg:w-80 h-32 lg:h-40 rounded-lg p-2 m-8 btn cursor-pointer" >
    <i class="text-2xl lg:text-6xl m-1" :class="icon"></i>
    <div class="z-10 bottom-0 left-0 text-cente w-full mb-2 p-2 ">
      <h1 class="m-2 font-semibold">{{ title }}</h1>
      <p class="hidden lg:inline text-green-100 text-sm lg:text-md">
        {{ infos }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HelloButton',
  props: ['title', 'infos', 'icon'],
  data: function() {
    return { hover: false }
  }
}
</script>
<style scoped>
  .btn {
    transition: transform 100ms ease-in-out;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

  .btn:hover {
    transform: scale(1.1);
    background-color: #99D1FF;
  }


</style>