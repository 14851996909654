import App from './App.vue'
import AOS from "aos";
import "aos/dist/aos.css";
import './assets/tailwind.css'
import axios from 'axios'
import { createApp } from 'vue'
import VueAxios from 'vue-axios'
import VueGtag from 'vue-gtag'
import Vuex from "vuex";
import store from './store'
import router from './router'

const myApp = createApp(App)
myApp.use(VueGtag, { config: { id: "G-CCY8851M30" } })
myApp.use(VueAxios, axios)

myApp.AOS = new AOS.init({ disable: "phone" });
myApp.use(Vuex);
myApp.use(store);
myApp.use(router);
myApp.mount('#app')