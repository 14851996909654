<template>
  <div class="">
    <nav class="flex justify-between flex-row bg-bootscutblue shadow-xl fixed top-0 z-30 w-full">
      <div class="ml-2 w-48 py-3 mr-4">
        <a href="/"><img alt="Grizzly Cogs" src="../../assets/logo.png" class="w-12"></a>
      </div>
      <div class="flex pt-8 text-xl text-white font-bold">ADMINISTRATION</div>
      <div class="flex py-4 text-xl">
        <!-- <MenuItem item-title="Albums" link="/admin/albums" :newtab="false" />
        <div class="vl mt-4 mb-4 text-bootscutblue"></div>
        <MenuItem item-title="Singles" link="/admin/singles" :newtab="false" />
        <div class="vl mt-4 mb-4"></div>
        <MenuItem item-title="Games" link="/admin/albums" :newtab="false" />
        <div class="vl mt-4 mb-4"></div>
        <MenuItem item-title="RRJP" link="/admin/albums" :newtab="false" />
        <div class="vl mt-4 mb-4"></div> -->
        <MenuItem item-title="Shows" link="/admin/shows" :newtab="false" />
      </div>
    </nav>
  </div>
</template>
<script>
import MenuItem from '../common/MenuItem'

export default {
  name: 'Menu',
  components: {
    MenuItem
  },
}
</script>
<style scoped>
  .vl {
    border-left: 1px solid white;
  }
</style>