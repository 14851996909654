import Vuex from "vuex";
 
export default new Vuex.Store({
 state: {
   popupdata: null,
   type: '',
   opened: false,
   isRRJP: false,
   menu: true,
 },
 getters: {},
 mutations: {
  open (state, payload) {
    state.popupdata = payload.item;
    state.type = payload.type;
    state.opened = true;
    state.isRRJP = payload.isRRJP ? payload.isRRJP : false
  },
  hideMenu () {
  }
 },
 actions: {

 }
});