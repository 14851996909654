<template>
    <div class="m-2 relative shadow-xl rounded" @mouseenter="hover = true" @mouseleave="hover = false">
      <router-link :to="getInfoUrl()">
        <img class="w-96 rounded" :src="'img/' + singleData.artcover" v-on:click="show = true" />
      </router-link>
      <div class="absolute flex bottom-0 right-0 m-2 text-xs xl:text-sm">
        <div class="hidden xl:block pr-2 pl-2 rounded-full bg-red text-white bg-opacity-90" v-if="singleData.type == 'Album'">
          album
        </div>
        <div class="hidden xl:block pr-2 pl-2 rounded-full bg-orange text-white bg-opacity-90" v-if="singleData.type == 'EP'">
          EP
        </div>
        <div class="hidden xl:block pr-2 pl-2 rounded-full bg-green text-white bg-opacity-90" v-if="singleData.type == 'Single'">
          single
        </div>
        <div class="ml-1 pr-2 pl-2 rounded-full bg-bootscutblue text-white bg-opacity-90">
          {{ singleData.year }}
        </div>
      </div>
      <transition name="fade">
        <div v-if="hover" class="absolute top-0 left-0 p-2 w-full h-full bg-bootscutblue bg-opacity-90 text-white rounded hidden lg:block">
          <div class="absolute bottom-2 text-3xl w-full text-center">
            <router-link :to="getInfoUrl()">
              <i class="fas fa-headphones-alt mr-8 cursor-pointer"></i>
            </router-link>
            <router-link :to="getInfoUrl()">
              <i class="fas fa-info-circle cursor-pointer"></i>
            </router-link>
          </div>
          <p class="tracking-wider text-xl text-white">{{ singleData.name }}</p>
        </div>
      </transition>
    </div>

</template>
<script>
export default {
  name: 'Single',
  props: ['singleData'],
  data: function() {
    return { hover: false }
  },
  methods: {
    getInfoUrl: function () {
      if (this.singleData.cat == 'project')
        return '/release/project/' + this.singleData.name;
      return '/release/bootscut/' + this.singleData.name;
    }
  }
}
</script>
<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter-from, .fade-leave-to {
    opacity: 0;
  }

</style>