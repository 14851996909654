<template>
<div class="absolute z-30 bg-black bg-opacity-40  w-full h-full" v-if="shown"></div>
<div class="absolute left-48 right-48 top-12 bg-whitesmoke z-30 rounded-lg" v-if="shown">
  <div class="m-2 text-bootscutblue text-2xl font-bold" v-if="!editMode">Nouveau show</div>
  <div class="m-2 text-bootscutblue text-2xl font-bold" v-if="editMode">Modifier show</div>
  <form class="mt-8 mx-8 text-bootscutblue font-bold">
    <div class="flex my-2">
      <div class="w-48 text-right p-2">Date de sortie : </div>
      <div class="w-96"><input v-model="editShow.date" class="rounded p-2 w-full" /></div>
      <div class="py-2 px-4">(Au format YYYY-MM-DD)</div>
    </div>
    <div class="flex my-2">
      <div class="w-48 text-right p-2">Artiste : </div>
      <div class="w-96">
        <select v-model="editShow.artist" class="rounded p-2 w-full">
          <option value="RRJP">Run! Run! Jump! Punch!</option>
          <option value="BOOTSCUT">Bootscut</option>
          <option value="FF">Fall Flavored</option>
          <option value="NPW">Nainportnawaks</option>
        </select>
      </div>
    </div>
    <div class="flex my-2">
      <div class="w-48 text-right p-2">Lieu : </div>
      <div class="w-96"><input v-model="editShow.place" class="rounded p-2 w-full" /></div>
      <div class="py-2 px-4">(Le nom du bar, de la salle, etc...)</div>
    </div>
    <div class="flex my-2">
      <div class="w-48 text-right p-2">Ville : </div>
      <div class="w-96"><input v-model="editShow.city" class="rounded p-2 w-full" /></div>
    </div>
    <div class="flex my-2">
      <div class="w-48 text-right p-2">Pays : </div>
      <div class="w-96">
        <select v-model="editShow.country" class="rounded p-2 w-full">
          <option value="France">France</option>
          <option value="Poland">Pologne</option>
          <option value="Holland">Pays-Bas</option>
          <option value="Belgium">Belgique</option>
          <option value="Germany">Allemagne</option>
          <option value="United Kingdom">Angleterre</option>
        </select>
      </div>
    </div>
    <div class="flex my-2">
      <div class="w-48 text-right p-2">Autres artistes : </div>
      <div class="w-96"><input v-model="editShow.artists" class="rounded p-2 w-full" /></div>
      <div class="py-2 px-4">(Séparés par des ,)</div>
    </div>
    <div class="flex">
      <div class="m-auto my-8 bg-bootscutblue text-white w-96 p-2 rounded-lg cursor-pointer" v-on:click="cancel()">
        <i class="fa-solid fa-ban pr-2"></i>Annuler
      </div>
      <div class="m-auto my-8 bg-bootscutblue text-white w-96 p-2 rounded-lg cursor-pointer" v-on:click="deleteIt()" v-if="editMode">
        <i class="fa-solid fa-trash pr-2"></i>Supprimer
      </div>
      <div class="m-auto my-8 bg-bootscutblue text-white w-96 p-2 rounded-lg cursor-pointer" v-on:click="submit()">
        <span v-if="!editMode"><i class="fa-solid fa-plus pr-2"></i> Ajouter le show</span>
        <span v-if="editMode"><i class="fa-solid fa-pencil pr-2"></i>Modifier le show</span>
      </div>
    </div>
  </form>
</div>
</template>
<script>
import axios from 'axios'

export default {
  props: ['shown', 'show', 'close', 'editMode'],
  data: function () {
    return { editShow: {}, editCover: false, newArtCover: false};
  },
  name: "editSinglePopUp",
  watch: { 
    show: function(newVal) {
      this.editCover = false;
      if (newVal) {
        this.editShow = newVal;
        if (!this.editShow.artist)
          this.editShow.artist = "";
        if (this.editShow.acts) {
          this.editShow.artists = this.actsToString(this.editShow.acts);
          console.log(this.editShow.artists);
        }
      }
      else 
        this.editShow = {};
    }
  },
  methods: {
    submit: async function() {
      if (this.editShow.artists && this.editShow.artists != "")
        this.editShow.acts = this.editShow.artists.split(',');
      const body = { show: this.editShow, password: window.localStorage.getItem('pwd')};
      if (this.editMode) {
        await axios.post("/.netlify/functions/show-edit", body);
      } else {
        await axios.post("/.netlify/functions/show-new", body);
      }
      this.close();
    },
    deleteIt: async function() {
      const body = { show: this.editShow, password: window.localStorage.getItem('pwd')};
      console.log(body);
      await axios.post("/.netlify/functions/show-delete", body);
      this.close();
    },
    actsToString: function(acts) {
      let artists = "";
      acts.forEach(a => {
        artists += a + ",";
      });
      artists = artists.slice(0, artists.length - 1);
      return artists;
    },
    cancel: function () {
      this.close();
    },
    doEditCover: function() {
      this.editCover = true;
    }
  },
}
</script>
