<template>
  <div class="lg:mt-28"></div>
  <SectionTitle name="BIO" />
  <div class="min-h-screen">
    <div class="lg:flex justify-center lg:mx-0 mx-8" >
      <div class="lg:w-1/3 text-justify p-4">
        <img src="/img/press-pix.jpg" />
      </div>
      <div class="lg:w-1/3 text-justify p-4">
        Bootscut est un auteur compositeur interprète originaire de Lille (France). Il produit des titres pour son projet solo de punk rock français et collabore avec des artistes tels que : Run ! Run ! Jump ! Punch !, Fall Flavored, Udon Box, Robotcosmic.
        <br /><br />
        Il est inspiré par toute la scène le punk rock des années 2000, la pop acidulée, le J-rock, la J-pop, les sons des années 80 et un soupçon de variété française. 
      </div>
    </div>
    <SectionTitle name="SUIVEZ MOI" />
    <div class="text-center text-3xl text-black">
      <a href="https://open.spotify.com/artist/1avphamkw01I4EEXe5QITr?si=Hard4hqfSA-MKVfoH4HMOQ" target="_blank"><i class="fab fa-spotify text-green-400"></i></a>
      <a href="https://bootscut.bandcamp.com/" target="_blank"><i class="fab fa-bandcamp text-green-400"></i></a>
            <a href="https://www.youtube.com/channel/UCE45f67xVMmObP39g50RX_A" target="_blank"><i class="fa-brands fa-youtube"></i></a>
      <a href="https://www.instagram.com/bootscutofficiel" target="_blank"><i class="fab fa-instagram text-green-400"></i></a>
      <a href="https://www.facebook.com/bootscut.music" target="_blank"><i class="fab fa-facebook text-green-400"></i></a>
      <br /><br />
    </div>
    <SectionTitle name="CONTACT" />
    <div class="py-4 bg-green-100 rounded-lg lg:mx-48">
      <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="form-name" value="contact" />
        <div class="m-4">
          <input type="text" name="subject" class="lg:w-96 w-64 h-8 rounded p-2" placeholder="Sujet"/>
        </div>
        <div class="m-4">
          <input type="mail" name="mail" class="lg:w-96 w-64 h-8 rounded p-2" placeholder="Votre e-mail" />
        </div>
        <div class="m-4">
          <textarea name="body" class="lg:w-96 w-64 h-36 rounded p-2" placeholder="Votre message"></textarea>
        </div>
        <button type="submit" class="rounded-full bg-bootscutblue px-12 py-2 text-xl text-white font-bold">Envoyer !</button>
      </form>
    </div>
    <SectionTitle name="CREDITS" />
    <div>
      <strong class="text-green-400">Web design and dev :</strong> Boris Warembourg
    </div>
  </div>
</template>
<script>
import SectionTitle from '../common/SectionTitle.vue'

export default {
  name: "Contact",
  components: { SectionTitle },
  mounted: function () {
    this.$gtag.event('contact', { method: 'Google' })
  }
}
</script>
<style scoped>
a {
  margin: 5px;
}

.hello {
  background-image: url("/img/contactme.jpg");
  background-size: cover;
  background-position: 0% 25%;
  height: 40rem;  
}

  .blur {
    backdrop-filter: blur(10px);
  }
</style>