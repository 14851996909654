<template>
  <SectionTitle name="ADMIN" />
  <div class="m-4 text-bootscutblue">
    Mot de passe :
    <input type="password" class="p-2" v-model="password" />
    <div class="bg-bootscutblue w-40 mx-auto rounded my-4 text-white cursor-pointer" v-on:click="auth()" >Accès Admin</div>
  </div>
</template>
<script>
import SectionTitle from '../common/SectionTitle'
import axios from 'axios'

export default {
  data: function () {
    return { password: "" }
  },
  components: { SectionTitle },
  mounted: function () {
    this.$store.commit('hideMenu');
  },
  methods: {
    auth: async function() {      
      const response = await axios.post("/.netlify/functions/auth", { password: this.password});
      if (response.status == 200) {
        window.localStorage.setItem('pwd', this.password);
        this.$router.push('/admin/shows')
      }
    }
  }
}

</script>
