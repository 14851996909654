<template>
  <Loader :loaded="loaded" />
  <div v-if="loaded" class="hidden lg:flex lg:mx-8 my-1">
    <div class="mx-2 pt-1 font-bold text-red-800">
      Filtrer : 
    </div>
    <div v-bind:class="{ 'bg-bootscutblue': !filtered}" 
      class="rounded-full bg-gray text-white px-4 py-1 cursor-pointer font-medium" v-on:click="filterBy('ALL')">
      <span v-if="!filtered">
        <i class="fas fa-check"></i>
      </span>
      Tout
    </div>
    <div class="rounded-full ml-1 bg-red text-white px-4 py-1 cursor-pointer font-medium" v-on:click="filterBy('album')">
      <span v-if="filter == 'album'">
        <i class="fas fa-check"></i>
      </span>
      album
    </div>
    <div class="rounded-full ml-1 bg-orange text-white px-4 py-1 cursor-pointer font-medium" v-on:click="filterBy('ep')">
      <span v-if="filter == 'ep'">
        <i class="fas fa-check"></i>
      </span>
      EP
    </div>
    <div class="rounded-full ml-1 bg-green text-white px-4 py-1 cursor-pointer font-medium" v-on:click="filterBy('single')">
      <span v-if="filter == 'single'">
        <i class="fas fa-check"></i>
      </span>
      single
    </div>
  </div>

  <div class="grid grid-cols-3 lg:grid-cols-5 lg:mx-8 my-1">
    <Single data-aos="flip-left" :data-aos-delay="getDelay(single)" v-for="single in filteredSingles" :key="single.id" :singleData="single" />
  </div>
</template>
<script>
  import axios from 'axios'
  import Single from './Single'
  import Loader from '../common/Loader'

  export default {
    components: { Single, Loader },
    name: 'SingleList',
    data: function() {
      return { singles: [], filteredSingles: [], loaded: false, filtered: false, filter: ''}
    },
    methods: {
      getDelay: function(single) {
        if (this.filtered)
          return 0;
        return single.id * 100;
      },
      filterBy: function(type) {
        if(type == 'ALL') {
          this.filtered = false;
          this.filter = '';
          this.filteredSingles = this.singles;
          return;
        }
        if (type == 'album') {
          this.filteredSingles = this.singles.filter(s => s.type == 'Album')
          this.filter = 'album';
        }
        if (type == 'ep') {
          this.filteredSingles = this.singles.filter(s => s.type == 'EP')
          this.filter = 'ep';
        }
        if (type == 'single') {
          this.filteredSingles = this.singles.filter(s => s.type == 'Single')
          this.filter = 'single';
        }
        this.filtered = true;
      }
    },
    mounted: function() {
        axios
          .get('/.netlify/functions/singles')
          .then(response => { 
            this.singles = response.data
            for (var i = 0; i < this.singles.length; i++) {
              this.singles[i].id = i;
            }
            this.filteredSingles = this.singles;
            this.loaded = true
          })
    }
  }
</script>