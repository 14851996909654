import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/home/Home.vue";
import Music from "./components/music/Music.vue";
import Projects from "./components/projects/Projects.vue";
import Live from "./components/live/Live.vue"
import Contact from "./components/contact/Contact.vue"
import ReleasePage from "./components/music/ReleasePage"
import Videos from "./components/videos/Videos"
import AdminPwd from "./components/admin/AdminPwd"
import AdminShows from "./components/admin/shows/AdminShows"

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/music",
    name: "Music",
    component: Music
  },
  {
    path: "/projets",
    name: "Projects",
    component: Projects
  },
  {
    path: "/live",
    name: "Live",
    component: Live
  },
  {
    path: "/videos",
    name: "Videos",
    component: Videos
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact
  },
  {
    path: "/release/:type/:id",
    name: "Release",
    component: ReleasePage,
    props: true
  },
  {
    path: "/admin",
    name: "Admin",
    component: AdminPwd,
  },
  {
    path: "/admin/shows",
    name: "Admin show",
    component: AdminShows
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;