<template>
  <div class="hidden xl:block" v-if="!hidden">
    <nav class="flex justify-between flex-row bg-bootscutblue shadow-xl fixed top-0 z-30 w-full">
      <div class="ml-2 w-48 py-3 ml-8">
        <a href="/"><img alt="Bootscut" src="../../assets/logo.png" class="w-14"></a>
      </div>
      <div class="flex py-4 text-xl">
        <MenuItem item-title="Musique" link="/music" :newtab="false" />
        <div class="vl mt-4 mb-4"></div>
        <MenuItem item-title="Mes projets" link="/projets" :newtab="false"/>
        <div class="vl mt-4 mb-4"></div>
        <MenuItem item-title="Vidéos" link="/videos" :newtab="false" />
        <div class="vl mt-4 mb-4"></div>
        <MenuItem item-title="Live" link="/live" :newtab="false" />
        <div class="vl mt-4 mb-4"></div>
        <MenuItem item-title="Contact / Bio" link="/contact" :newtab="false" />
        <div class="vl mt-4 mb-4"></div>
        <MiniMenuItem icon="fab fa-spotify" link="https://open.spotify.com/artist/1avphamkw01I4EEXe5QITr?si=Hard4hqfSA-MKVfoH4HMOQ" />
        <MiniMenuItem icon="fab fa-bandcamp" link="https://bootscut.bandcamp.com/" />
        <MiniMenuItem icon="fa-brands fa-youtube" link="https://www.youtube.com/channel/UCE45f67xVMmObP39g50RX_A" />
        <MiniMenuItem icon="fab fa-instagram" link="https://www.instagram.com/bootscutofficiel/" />
        <MiniMenuItem icon="fab fa-facebook" link="https://www.facebook.com/bootscut.music" />
      </div>
      
    </nav>
  </div>

  <!-- MOBILE -->
  
  <!-- open button -->
  <div class="xl:hidden fixed top-2 right-2 bg-bootscutblue px-2 py-1 rounded text-lg text-white z-30 cursor-pointer" v-if="!menuOpened && !hidden">
    <i class="fas fa-bars" v-on:click="menuOpened = true"></i>
  </div>
  <!-- close button -->
  <div class="xl:hidden fixed top-2 right-2 text-2xl text-green-400 z-50 cursor-pointer" v-if="menuOpened">
    <i class="fas fa-times" v-on:click="menuOpened = false"></i>
  </div>

  <div class="xl:hidden bg-white w-full h-full fixed bg-opacity-90 z-30 text-left pl-8 py-2 leading-10" v-if="menuOpened">
    <div class="text-center text-2xl text-green-400">Menu</div>
    <a href="/music" class="text-green-400 text-2xl my-2" v-on:click="menuOpened=false">Musique</a><br />    
    <a href="/projets" class="text-green-400 text-2xl my-2" v-on:click="menuOpened=false">Mes projets</a><br />
    <a href="/videos" class="text-green-400 text-2xl my-2" v-on:click="menuOpened=false">Videos</a><br />
    <a href="/live" class="text-green-400 text-2xl my-2" v-on:click="menuOpened=false">Live</a><br />
    <a href="/contact" class="text-green-400 text-2xl my-2" v-on:click="menuOpened=false">Contact / Bio</a><br />
    <a href="https://open.spotify.com/artist/1avphamkw01I4EEXe5QITr?si=Hard4hqfSA-MKVfoH4HMOQ" class="text-green-400 text-2xl m-2"><i class="fab fa-spotify"></i></a>
    <a href="https://bootscut.bandcamp.com/" class="text-green-400 text-2xl my-2 mr-2"><i class="fab fa-bandcamp"></i></a>
    <a href="https://www.youtube.com/channel/UCE45f67xVMmObP39g50RX_A" class="text-green-400  text-2xl m-2"><i class="fa-brands fa-youtube"></i></a>
    <a href="https://www.instagram.com/bootscutofficiel/" class="text-green-400 text-2xl m-2"><i class="fab fa-instagram"></i></a>
    <a href="https://www.facebook.com/bootscut.music" class="text-green-400  text-2xl m-2"><i class="fab fa-facebook"></i></a>
  </div>
</template>

<script>
import MenuItem from './MenuItem'
import MiniMenuItem from './MiniMenuItem'

export default {
  name: 'Menu',
  components: {
    MenuItem, MiniMenuItem
  },
  data: function() {
    return {menuOpened: false, hidden: false}
  },
  mounted: function() {
    this.$store.subscribe((mutation) => {
      //OPEN
      if (mutation.type == 'hideMenu') {
        this.hidden = true;
      } else {
        this.hidden = false;
      }
    });
  }
}
</script>
<style scoped>
  .vl {
    border-left: 1px solid white;
  }
</style>