<template>
  <div class="lg:mt-28"></div>
  <SectionTitle id="shows" name="BOOTSCUT LIVE" />

  <div class="lg:flex p-4">
  <div class="lg:w-1/4 w-full">
    <img src="img/live1.jpg" />
  </div>
    <div class="w-full p-4">
      <ShowList />
    </div>
  </div>
</template>
<script>
import SectionTitle from '../common/SectionTitle'
import ShowList from './ShowList'

export default {
  name: 'GrizzlyCogsLive',
  components: { ShowList, SectionTitle },
  methods: {
    gallery: function() {
      return [
        { id: 0, src: "img/live1.jpg", credit: "Loïse Doyen", link: "https://loisedoyen.com/" },
        { id: 1, src: "img/live2.jpg", credit: "Loïse Doyen", link: "https://loisedoyen.com/" },
      ]
    }
  },
  mounted: function () {
    this.$gtag.event('live', { method: 'Google' })
  }
}
</script>