<template>
  <div class="lg:mt-28"></div>
  <SectionTitle name="NOUVEL EP DISPONIBLE !" />
  <Highlight :highlight="highlight" />
  <SectionTitle name="MUSIQUES" />
  <SubTitle name="SINGLES & EPS" />
  <SingleList />
</template>
<script>
import SectionTitle from '../common/SectionTitle'
import Highlight from '../common/Highlight'
import SubTitle from '../common/SubTitle'
import SingleList from './SingleList'
import axios from 'axios'

export default {
  components: { SubTitle, SingleList, SectionTitle, Highlight },
  name: 'Music',
  data: function () {
    return { highlight: {}, albums: [] }
  },
  mounted: function() {
    this.$gtag.event('music', { method: 'Google' })
    axios
      .get('/.netlify/functions/hl-albums')
      .then(response => { 
        this.highlight = response.data[0];
    });
  }
}
</script>