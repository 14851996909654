<template>
  <Hello />
</template>

<script>
import Hello from './Hello'

export default {
  name: 'App',
  components: {
    Hello,
  },
  mounted: function () {
    this.$gtag.event('home', { method: 'Google' })
  }
}
</script>
